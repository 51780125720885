<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            editedDevice.id
              ? $localizationService.localize(
                  "device_page.dialog.add_and_edit.edit_title"
                )
              : $localizationService.localize(
                  "device_page.dialog.add_and_edit.new_title"
                )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              v-model="editedDevice.name"
              :label="
                $localizationService.localize(
                  'device_page.dialog.add_and_edit.param.name'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedDevice.number"
              :label="
                $localizationService.localize(
                  'device_page.dialog.add_and_edit.param.number'
                )
              "
              :rules="phoneRules"
              :disabled="loading"
            ></v-text-field>
            <div>Please enter number in international format.<br>Example: +15168887766</div>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="open = false">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addOrSaveDevice"
          :disabled="saveButtonDisabled"
        >
          {{
            editedDevice.id
              ? $localizationService.localize("btn.save")
              : $localizationService.localize("btn.add")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import PhoneNumberValidator from "../../utils/phone-number-validator";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditDevicesDialog",

  data: () => ({
    open: false,
    loading: false,

    editedDeviceOriginal: {},
    editedDevice: {},

    phoneRules: [
      (value) => !value
          || PhoneNumberValidator.isValid(value)
          || locService.localize("error.validation.valid_phone"),
    ],
  }),

  computed: {
    saveButtonDisabled() {
      return !this.editedDevice.name
          || !this.editedDevice.number
          || !this.phoneRules.find((rule) => rule(this.editedDevice.number) === true)
          || this.loading;
    },
  },

  methods: {
    openDialog(device) {
      this.editedDeviceOriginal = device || {};
      this.editedDevice = { ...this.editedDeviceOriginal };
      this.open = true;
    },

    async addOrSaveDevice() {
      this.loading = true;
      await this.$store.dispatch(
        "devicesModule/addOrSaveDevice",
        this.editedDevice,
      );
      this.loading = false;
      this.open = false;
    },
  },
};
</script>
